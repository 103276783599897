<template>
  <div
    class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 flex items-start"
  >
    <div v-for="(service, i) in groupsSections" :key="i">
      <ServicesVue
        :service="service"
        :i="i"
        @whichBreed="whichBreed(service, i)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ServicesVue from "./Services.vue";

export default {
  components: {
    ServicesVue,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      serviceDirectory: "servicedirectory/getServiceDirectories",
      groupsSections: "breederdirectory/getGroupsSections",
    }),
  },
  created() {
    this.getGroupsSections();
    console.log(this.groupsSections);
  },

  methods: {
    ...mapActions({
      getServiceDirectoryType1: "servicedirectory/getServiceDirectoryType1",
      getServiceDirectoryType2: "servicedirectory/getServiceDirectoryType2",
      getServiceDirectoryType3: "servicedirectory/getServiceDirectoryType3",
      getServiceDirectoryType4: "servicedirectory/getServiceDirectoryType4",
      getServiceDirectoryType6: "servicedirectory/getServiceDirectoryType6",
      getServiceDirectoryType8: "servicedirectory/getServiceDirectoryType8",
      getGroupsSections: "breederdirectory/getGroupsSections",
    }),
  },
};
</script>

<style></style>
